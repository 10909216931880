<template>
    <div class="flex flex-row justify-content-between">
        <div class="field-checkbox mr-2">
            <InputSwitch id="pcd" v-model="switchDeficienciaFisica"/>
            <label for="pcd">Deficiência física</label>
        </div>
        <div class="field-checkbox mr-2">
            <InputSwitch id="pcd" v-model="switchDeficienciaAuditiva"/>
            <label for="pcd">Deficiência auditiva</label>
        </div>
        <div class="field-checkbox mr-2">
            <InputSwitch id="pcd" v-model="switchDeficienciaVisual"/>
            <label for="pcd">Deficiência visual</label>
        </div>
        <div class="field-checkbox mr-2">
            <InputSwitch id="pcd" v-model="switchDeficienciaIntelectual"/>
            <label for="pcd">Deficiência intelectual</label>
        </div>
        <div class="field-checkbox mr-2">
            <InputSwitch id="pcd" v-model="switchDeficienciaReabilitado"/>
            <label for="pcd">Reabilitado</label>
        </div>
    </div>

    <div v-if="switchDeficienciaFisica" class="mt-2">
        <h5>
            Deficiência física
            <i class="pi pi-info-circle ml-1" @mouseleave="toggleFisica" @mouseover="toggleFisica"></i>
        </h5>
        <OverlayPanel ref="opFisica">
            <h6><strong>Descrição</strong></h6>
            <p>Alteração completa ou parcial de um ou mais segmentos do corpo humano, acarretando o comprometimento da
                função física.</p>
            <ul>
                <li>Alteração da força</li>
                <li>Alterações articulares</li>
                <li>Ostomias</li>
                <li>Nanismo</li>
                <li>Paralisia cerebral</li>
                <li>Amputações, ausência ou deformidades de membros</li>
                <li>Outras alterações dos segmentos corporais</li>
                <li>Deformidades estéticas</li>
            </ul>
        </OverlayPanel>

        <div class="p-fluid grid">
            <div class="field col-6 m-0">
                <label for="fisicasResposta1">
                    Possui parecer de profissional de saúde de nível superior habilitado na área da deficiência?
                </label>
                <InputText
                    id="fisicasResposta1"
                    v-model="form.fisicas.resposta1"
                    placeholder="Insira a resposta aqui"
                    required="true"/>
                <small v-if="!form.fisicas.resposta1" class="p-error">Campo
                    obrigatório.</small>
            </div>
            <div class="field col-6 m-0">
                <label for="fisicasResposta2">
                    Possui laudo de enquadramento do empregado como pessoa com deficiência?
                    <i class="pi pi-info-circle ml-1"
                       title='Possui laudo de enquadramento do empregado como pessoa com deficiência, assinado por profissional de saúde de nível superior, preferencialmente habilitado na área de deficiência relacionada ou em saúde do trabalho?'></i>
                </label>
                <InputText
                    id="nomeSolicitante"
                    v-model="form.fisicas.resposta2"
                    placeholder="Insira a resposta aqui"
                    required="true"/>
                <small v-if="!form.fisicas.resposta2" class="p-error">Campo
                    obrigatório.</small>
            </div>
        </div>
    </div>

    <div v-if="switchDeficienciaAuditiva" class="mt-2">
        <h5>
            Deficiência auditiva
            <i class="pi pi-info-circle ml-1" @mouseleave="toggleAuditiva" @mouseover="toggleAuditiva"></i>
        </h5>
        <OverlayPanel ref="opAuditiva">
            <h6><strong>Descrição</strong></h6>
            <p>É a perda bilateral da audição, parcial ou total, de quarenta e um decibéis (dB) ou mais, aferida por
                audiograma nas frequências de 500HZ, 1.000HZ, 2.000Hz e 3.000Hz.</p>
        </OverlayPanel>

        <div class="p-fluid grid">
            <div class="field col-6 m-0">
                <label for="auditivasResposta1">
                    Perda em cada ouvido de 41 (dB) ou mais?
                </label>
                <InputText
                    id="auditivasResposta1"
                    v-model="form.auditivas.resposta1"
                    placeholder="Insira a resposta aqui"
                    required="true"/>
                <small v-if="!form.auditivas.resposta1" class="p-error">Campo
                    obrigatório.</small>
            </div>
            <div class="field col-6 m-0">
                <label for="auditivasResposta2">
                    Audiograma assinado por médico otorrinolaringologista ou fonoaudiólogo?
                </label>
                <InputText
                    id="auditivasResposta2"
                    v-model="form.auditivas.resposta2"
                    placeholder="Insira a resposta aqui"
                    required="true"/>
                <small v-if="!form.auditivas.resposta2" class="p-error">Campo
                    obrigatório.</small>
            </div>
            <div class="field col-6 m-0">
                <label for="auditivasResposta3">
                    Laudo de enquadramento do empregado como pessoa com deficiência?
                    <i class="pi pi-info-circle ml-1"
                       title='Laudo de enquadramento do empregado como pessoa com deficiência, assinado por profissional de saúde de nível superior, preferencialmente habilitado na área de deficiência relacionada ou em saúde do trabalho?'></i>
                </label>
                <InputText
                    id="auditivasResposta3"
                    v-model="form.auditivas.resposta3"
                    placeholder="Insira a resposta aqui"
                    required="true"/>
                <small v-if="!form.auditivas.resposta3" class="p-error">Campo
                    obrigatório.</small>
            </div>
        </div>
    </div>

    <div v-if="switchDeficienciaVisual" class="mt-2">
        <h5>
            Deficiência visual
            <i class="pi pi-info-circle ml-1" @mouseleave="toggleVisual" @mouseover="toggleVisual"></i>
        </h5>
        <OverlayPanel ref="opVisual">
            <h6><strong>Considera-se deficiência visual</strong></h6>
            <ul>
                <li>Cegueira, na qual a acuidade visual é igual ou menor que 0,05 (20/400) no melhor olho, com a melhor
                    correção óptica
                </li>
                <li>Baixa visão, que significa acuidade visual entre 0,3 (20/60) e 0,05 (20/400) no melhor olho, com a
                    melhor correção óptica
                </li>
                <li>Somatória da medida do campo visual em ambos os olhos for igual ou menor que 60°</li>
                <li>Ocorrência simultânea de quaisquer das condições anteriores</li>
            </ul>
            <p>Quanto ao campo visual, que é a área de visão lateral, superior e inferior que se pode enxergar ao olhar
                em
                frente, a referência legal é de que seja menor ou igual a 60°. Não é um percentual, mas uma medida de
                ângulos de visão.</p>
            <p>Sendo assim, o exame complementar denominado Campimetria é necessário, assim como o laudo do
                oftalmologista
                declarando qual é o somatório do campo visual em graus, já que é um exame de difícil interpretação por
                outros profissionais.</p>
        </OverlayPanel>

        <div class="p-fluid grid">
            <div class="field col-6 m-0">
                <label for="visuaisResposta1">
                    Acuidade visual com correção de 20/60 ou pior nos dois olhos ou campo visual menor ou igual a 60°?
                </label>
                <InputText
                    id="visuaisResposta1"
                    v-model="form.visual.resposta1"
                    placeholder="Insira a resposta aqui"
                    required="true"/>
                <small v-if="!form.visual.resposta1" class="p-error">Campo
                    obrigatório.</small>
            </div>
            <div class="field col-6 m-0">
                <label for="visuaisResposta2">
                    Laudo oftalmológico com tabela Snellen ou campimetria em graus?
                </label>
                <InputText
                    id="visuaisResposta2"
                    v-model="form.visual.resposta2"
                    placeholder="Insira a resposta aqui"
                    required="true"/>
                <small v-if="!form.visual.resposta2" class="p-error">Campo
                    obrigatório.</small>
            </div>
            <div class="field col-6 m-0">
                <label for="visuaisResposta3">
                    Laudo de enquadramento do empregado como pessoa com deficiência?
                    <i class="pi pi-info-circle ml-1"
                       title='Laudo de enquadramento do empregado como pessoa com deficiência, assinado por profissional de saúde de nível superior, preferencialmente habilitado na área de deficiência relacionada ou em saúde do trabalho?'></i>
                </label>
                <InputText
                    id="visuaisResposta3"
                    v-model="form.visual.resposta3"
                    placeholder="Insira a resposta aqui"
                    required="true"/>
                <small v-if="!form.visual.resposta3" class="p-error">Campo
                    obrigatório.</small>
            </div>
            <div class="field col-6 m-0">
                <label for="visuaisResposta4">
                    Acuidade visual com correção de 20/60 ou pior nos dois olhos ou campo visual menor ou igual a 60°?
                </label>
                <InputText
                    id="visuaisResposta4"
                    v-model="form.visual.resposta4"
                    placeholder="Insira a resposta aqui"
                    required="true"/>
                <small v-if="!form.visual.resposta4" class="p-error">Campo
                    obrigatório.</small>
            </div>
            <div class="field col-6 m-0">
                <label for="visuaisResposta5">
                    Laudo oftalmológico com tabela Snellen ou campimetria emgraus?
                </label>
                <InputText
                    id="visuaisResposta5"
                    v-model="form.visual.resposta5"
                    placeholder="Insira a resposta aqui"
                    required="true"/>
                <small v-if="!form.visual.resposta5" class="p-error">Campo
                    obrigatório.</small>
            </div>
            <div class="field col-6 m-0">
                <label for="visuaisResposta6">
                    Laudo de enquadramento do empregado como pessoa com deficiência?
                    <i class="pi pi-info-circle ml-1"
                       title='Laudo de enquadramento do empregado como pessoa com deficiência, assinado por profissional de saúde de nível superior, preferencialmente habilitado na área de deficiência relacionada ou em saúde do trabalho?'></i>
                </label>
                <InputText
                    id="visuaisResposta6"
                    v-model="form.visual.resposta6"
                    placeholder="Insira a resposta aqui"
                    required="true"/>
                <small v-if="!form.visual.resposta6" class="p-error">Campo
                    obrigatório.</small>
            </div>
        </div>

        <Message :closable="false" severity="warn">
            PONTO DE ATENÇÃO
            <p>
                A visão monocular está sendo considerada para fins de cumprimento da cota, mesmo com parecer contrário
                do
                CONADE.
                Ocorre quando há cegueira, na qual a acuidade visual com melhor correção óptica é igual ou menor que
                0,05
                (20/400), visão de vultos, conta-dedos em um olho, ou cegueira legal declarada pelo oftalmologista, ou
                uso
                de prótese, ou olho enucleado ou
                Phthisis bulbi.
                Há casos de acuidade 20/200 ou melhor, mas que, pela existência de comorbidades, como estrabismo (desvio
                do
                eixo de um dos olhos), o olho não é funcional, caso o oftalmologista informe visão monocular
                explicitamente
                no laudo, pode-se aceitar.
            </p>
        </Message>
    </div>

    <div v-if="switchDeficienciaIntelectual" class="mt-2">
        <h5>
            Deficiência intelectual
            <i class="pi pi-info-circle ml-1" @mouseleave="toggleIntelectual" @mouseover="toggleIntelectual"></i>
        </h5>
        <OverlayPanel ref="opIntelectual">
            <h6><strong>Descrição</strong></h6>
            <p>São aquelas pessoas com diagnósticos de “retardo mental” pela Classificação Internacional de Doenças
                (CID).
                Também as síndromes como a Síndrome de Down e outras que cursam com rebaixamento intelectual podem
                entrar
                neste enquadramento</p>
            <p>Funcionamento intelectual significativamente inferior à média, com manifestação antes dos 18 anos e
                limitações associadas a duas ou mais
                habilidades adaptativas, tais como:</p>
            <ul>
                <li>Comunicação</li>
                <li>Cuidado pessoal</li>
                <li>Habilidades sociais</li>
                <li>Utilização de recursos da comunidade</li>
                <li>Saúde e segurança</li>
                <li>Habilidades acadêmicas</li>
                <li>Lazer</li>
                <li>Trabalho</li>
            </ul>
        </OverlayPanel>

        <div class="p-fluid grid">
            <div class="field col-6 m-0">
                <label for="intelectualResposta1">
                    Alterações para habilidades adaptativas, sempre duas ou mais?
                </label>
                <InputText
                    id="intelectualResposta1"
                    v-model="form.intelectual.resposta1"
                    placeholder="Insira a resposta aqui"
                    required="true"/>
                <small v-if="!form.intelectual.resposta1" class="p-error">Campo obrigatório.</small>
            </div>
            <div class="field col-6 m-0">
                <label for="intelectualResposta2">
                    Laudo de deficiência intelectual, assinado por especialista em saúde mental/intelectual (psicólogo,
                    psiquiatra, neurologista)
                </label>
                <InputText
                    id="intelectualResposta2"
                    v-model="form.intelectual.resposta2"
                    placeholder="Insira a resposta aqui"
                    required="true"/>
                <small v-if="!form.intelectual.resposta2" class="p-error">Campo obrigatório.</small>
            </div>
            <div class="field col-6 m-0">
                <label for="intelectualResposta3">
                    Laudo de enquadramento do empregado como pessoa com deficiência, assinado por profissional de saúde
                    de nível superior, preferencialmente habilitado na área de deficiência relacionada ou em saúde do
                    trabalho
                </label>
                <InputText
                    id="intelectualResposta3"
                    v-model="form.intelectual.resposta3"
                    placeholder="Insira a resposta aqui"
                    required="true"/>
                <small v-if="!form.intelectual.resposta3" class="p-error">Campo obrigatório.</small>
            </div>
            <div class="field col-6 m-0">
                <label for="intelectualResposta4">
                    Deficiência mental/psicossocial
                </label>
                <InputText
                    id="intelectualResposta4"
                    v-model="form.intelectual.resposta4"
                    placeholder="Insira a resposta aqui"
                    required="true"/>
                <small v-if="!form.intelectual.resposta4" class="p-error">Campo obrigatório.</small>
            </div>
        </div>
    </div>

    <div v-if="switchDeficienciaReabilitado" class="mt-2">
        <h5>
            Reabilitado
            <i class="pi pi-info-circle ml-1" @mouseleave="toggleReabilitado" @mouseover="toggleReabilitado"></i>
        </h5>
        <OverlayPanel ref="opReabilitado">
            <h6><strong>Descrição</strong></h6>
            <p>Pessoa que passou por processo de reabilitação junto ao Instituto Nacional de Seguro Social (INSS) e
                recebeu
                um Certificado de Reabilitação Profissional.</p>
        </OverlayPanel>

        <div class="p-fluid grid">
            <div class="field col-6 m-0">
                <label for="reabilitadoResposta1">
                    Apresentação do Certificado de Reabilitação Profissional emitido pelo INSS?
                </label>
                <InputText
                    id="reabilitadoResposta1"
                    v-model="form.reabilitado.resposta1"
                    placeholder="Insira a resposta aqui"
                    required="true"/>
                <small v-if="!form.reabilitado.resposta1" class="p-error">Campo obrigatório.</small>
            </div>
        </div>

    </div>
    <div class="field col-3 m-0" style="width: 100%;">
            <label for="anexo">
                Anexo do Enquadramento PCD <span class="p-error">*</span>
            </label>
            <div style="width: 50%;">
                <FileUpload
                        mode="advanced"
                        @uploader="uploadAnexo"
                        auto
                        customUpload
                        :maxFileSize="10485760"
                        :showCancelButton="false"
                        :showUploadButton="false"
                        invalidFileSizeMessage="O arquivo selecionado excede o tamanho de 10 Mb."
                        label="Importar"
                        chooseLabel="Importar"
                        class="mr-2 inline-block"
                        :style="{ width: '100%' }"
                    >
                        <template #empty>
                            <p>Arraste e solte o arquivo aqui.</p>
                        </template>
                </FileUpload>
                <DataTable
                    v-if="anexos.length"
                    dataKey="id"
                    :value="anexos"
                    :row-hover="true"
                    class="p-datatable-sm"
                    responsiveLayout="stack"
                    breakpoint="640px"
                >
                    <template #empty> Nenhum registro encontrado. </template>
    
                    <Column field="nome" header="Nome">
                    <template #body="{ data }">
                        <a :href="data.url" class="white-space-nowrap overflow-hidden text-overflow-ellipsis" style="max-width: 60vw" target="_blank">
                        {{ data.nome }}
                        </a>
                    </template>
                    </Column>
                    <Column field="tipo" header="Tipo"></Column>
                    <Column :exportable="false" style="min-width: 2rem">
                    <template #body="{data}">
                        <Button icon="pi pi-trash" class="p-button-text p-button-secondary" @click="removerAnexo(data)" />
                    </template>
                    </Column>
                </DataTable>
            </div>
        </div>
</template>

<script>
export default {
    name: 'FormSolicitacoesPcd',
    props: {
        funcionarioId: Number
    },
    emits: ['update'],
    data() {
        return {
            form: {
                fisicas: {},
                auditivas: {},
                visual: {},
                intelectual: {},
                reabilitado: {}
            },
            switchDeficienciaFisica: false,
            switchDeficienciaAuditiva: false,
            switchDeficienciaVisual: false,
            switchDeficienciaIntelectual: false,
            switchDeficienciaReabilitado: false,
            anexos: []
        };
    },
    watch: {
        form: {
            handler() {
                this.$emit('update', { funcionarioId: this.funcionarioId, infoPcd: this.form, anexoPCD: this.anexos });
            },
            deep: true
        }
    },
    methods: {
        toggleFisica(event) {
            this.$refs.opFisica.toggle(event);
        },
        toggleAuditiva(event) {
            this.$refs.opAuditiva.toggle(event);
        },
        toggleVisual(event) {
            this.$refs.opVisual.toggle(event);
        },
        toggleIntelectual(event) {
            this.$refs.opIntelectual.toggle(event);
        },
        toggleReabilitado(event) {
            this.$refs.opReabilitado.toggle(event);
        },
        uploadAnexo(event) {
            const arquivoExistente = this.anexos.filter((anexo) => anexo.nome === event.files[0].name);

            if (arquivoExistente.length) {
                this.$toast.add({ severity: 'info', summary: 'Você já carregou este arquivo!', life: 3000 });
                return
            }
            this.anexos.push({
                nome: event.files[0].name,
                arquivo: event.files[0],
                tipo: 'Outros',
            });
            this.$emit('update', { funcionarioId: this.funcionarioId, infoPcd: this.form, anexoPCD: this.anexos });
        },
        removerAnexo(index) {
           this.anexos = this.anexos.filter((anexo) => anexo.nome !== index.nome);
           this.$emit('update', { funcionarioId: this.funcionarioId, infoPcd: this.form, anexoPCD: this.anexos });
        },
    }
};
</script>

<style scoped>

</style>

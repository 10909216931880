<template>
    <div class="p-fluid grid">
        <div class="field col-3 m-0">
            <label :class="{'p-error':v$.dataHomologacao.$invalid && this.submitted}" for="dataHomologacao">
                Data de demissão <span class="p-error">*</span>
            </label>
            <Calendar
                id="dataHomologacao"
                v-model="dataHomologacao"
                :class="{'p-invalid':v$.dataHomologacao.$invalid && this.submitted}"
                :showButtonBar="true"
                :showIcon="true"
                dateFormat="dd/mm/yy"
                selectionMode="single"/>
            <small v-if="(v$.dataHomologacao.$invalid && this.submitted)" class="p-error">
                {{ v$.dataHomologacao.required.$message }}
            </small>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

export default {
    setup: () => ({ v$: useVuelidate() }),
    props: {
        funcionarioId: {
            type: Number,
            required: true
        },
        submitted: {
            type: Boolean,
            required: true
        }
    },
    emits: ['update'],
    data() {
        return {
            dataHomologacao: null
        };
    },
    validations() {
        return {
            dataHomologacao: { required: helpers.withMessage('A data de demissão deve ser informada', required) },
        };
    },
    watch: {
        dataHomologacao() {
            this.$emit('update', { funcionarioId: this.funcionarioId, dataHomologacao: this.dataHomologacao });
        }
    }
};
</script>

<style scoped>

</style>

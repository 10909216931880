<template>
    <div class="p-fluid grid">
        <div class="field col-12 m-0">
            <label :class="{ 'p-error': v$.detalheConsulta.$invalid && this.submitted }" for="detalheConsulta">
                Detalhes da consulta <span class="p-error">*</span>
            </label>
            <Textarea
                v-model="detalheConsulta"
                :autoResize="true"
                :class="{ 'p-invalid': v$.detalheConsulta.$invalid && this.submitted }"
                cols="20"
                rows="3"
                type="text"
            />
            <small v-if="v$.detalheConsulta.$invalid && this.submitted" class="p-error">
                {{ v$.detalheConsulta.required.$message }}
            </small>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

export default {
    setup: () => ({ v$: useVuelidate() }),
    props: {
        funcionarioId: {
            type: Number,
            required: true
        },
        submitted: {
            type: Boolean,
            required: true
        }
    },
    emits: ['update'],
    data() {
        return {
            detalheConsulta: null
        };
    },
    validations() {
        return {
            detalheConsulta: { required: helpers.withMessage('Os detalhes da consulta devem ser informados', required) }
        };
    },
    watch: {
        detalheConsulta() {
            this.$emit('update', { funcionarioId: this.funcionarioId, detalheConsulta: this.detalheConsulta });
        }
    }
};
</script>

<template>
    <Card class="mt-5 no-border no-padding DefinirSolicitacoesstep0">
        <template #title> Concluir solicitações <TourComponent tourName="TelaSolicitacoesGuiaGestaoMedica" /></template>
        <template #content>
            <DataTable v-model:expandedRows="expandedRows" :value="form.funcionarios" dataKey="id">
                <Column class="DadosSolictacoesstep1" expander />
                <Column class="DadosSolictacoesstep2" field="name" header="Nome" style="width: 35%"></Column>
                <Column class="DadosSolictacoesstep3" field="email" header="E-mail" style="width: 20%"></Column>
                <Column class="DadosSolictacoesstep4" field="phone" header="Telefone" style="width: 20%"></Column>
                <Column header="Tipo" class="DadosSolictacoesstep5">
                    <template #body>
                        {{ form.tipoExame.descricao }}
                    </template>
                </Column>
                <Column header="PCD">
                    <template #body="{ data }">
                        <InputSwitch class="DadosSolictacoesstep6" id="pcd" v-model="data.pcd" @change="setVisibleLines" />
                    </template>
                </Column>
                <template #expansion="{ data: funcionario }">
                    <div class="p-3">
                        <FormSolicitacoesRisco
                            v-if="!funcionario.possuiRisco && !funcionario.customer.desabilitarQuestionarioRisco"
                            :ref="'FormSolicitacoesRisco' + funcionario.id"
                            :funcionarioId="funcionario.id"
                            :submitted="submitted"
                            @update="setInfoRisco"
                        />
                        <FormSolicitacoesRetorno
                            v-if="this.formData.tipoExame.id === tipoExame.RETORNO_AO_TRABALHO"
                            :ref="'FormSolicitacoesRetorno' + funcionario.id"
                            :funcionarioId="funcionario.id"
                            :submitted="submitted"
                            @update="setInfoRetorno"
                        />
                        <FormSolicitacoesConsulta
                            v-if="this.formData.tipoExame.id === tipoExame.CONSULTA"
                            :ref="'FormSolicitacoesConsulta' + funcionario.id"
                            :funcionarioId="funcionario.id"
                            :submitted="submitted"
                            @update="setInfoConsulta"
                        />
                        <FormSolicitacoesDemissao
                            v-if="this.formData.tipoExame.id === tipoExame.DEMISSIONAL"
                            :ref="'FormSolicitacoesDemissao' + funcionario.id"
                            :funcionarioId="funcionario.id"
                            :submitted="submitted"
                            @update="setInfoDemissao"
                        />
                        <FormSolicitacoesPcd :ref="'FormSolicitacoesPcd' + funcionario.id" v-if="funcionario.pcd" :funcionarioId="funcionario.id" @update="setInfoPcd" />
                    </div>
                </template>
            </DataTable>
        </template>
        <template #footer>
            <Button :loading="isLoading" class="w-2 p-button-outlined DadosSolictacoesstep7" label="Voltar" @click="prevPage()" />
            <Button
                :loading="isLoading"
                class="w-3"
                label="Criar agendamento"
                style="float: right"
                @click="criarAgendamento()"
            />
            <Button
                :loading="isLoading"
                class="w-3 p-button-outlined mr-2 DadosSolictacoesstep9"
                label="Criar pré-solicitação"
                style="float: right"
                :disabled="validarPrestadorUsaReversaHorario()"
                @click="criarPreSolicitacao()"
            />
        </template>
    </Card>
</template>

<script>
import FormSolicitacoesPcd from './FormSolicitacoesPcd.vue';
import TipoExame from '../../../../enums/TipoExame';
import FormSolicitacoesDemissao from './FormSolicitacoesDemissao.vue';
import FormSolicitacoesConsulta from './FormSolicitacoesConsulta.vue';
import FormSolicitacoesRetorno from './FormSolicitacoesRetorno.vue';
import FormSolicitacoesRisco from './FormSolicitacoesRisco.vue';
import TourComponent from '../../../../components/TourComponent';

export default {
    components: {
        FormSolicitacoesRisco,
        FormSolicitacoesRetorno,
        FormSolicitacoesConsulta,
        FormSolicitacoesDemissao,
        FormSolicitacoesPcd,
        TourComponent
    },
    props: {
        formData: Object,
        isLoading: Boolean
    },
    emits: ['prev-page', 'save-pre-solicitacao', 'save-agendamento'],
    data() {
        return {
            submitted: false,
            form: this.formData,
            expandedRows: [],
            tipoExame: TipoExame,
            anexos: null,
        };
    },
    activated() {
        this.setVisibleLines();
    },
    // watch: {
    //     expandedRows() {
    //         this.setVisibleLines();
    //     }
    // },
    computed: {
        prestadorUsaSoc() {
            return this.formData.prestador && this.formData.prestador.systemsAndTechnology?.name === 'SOC';
        }
    },
    methods: {
        validarPrestadorUsaReversaHorario() {
            if(this.form?.prestador?.idAgenda && this.form?.cliente?.usaReservaHorario){
                return true;
            }
            return false;
        },
        setInfoPcd(data) {
            const funcionario = this.form.funcionarios.find((element) => element.id === data.funcionarioId);
            funcionario.informacoesDeficienciasPcd = data.infoPcd;
            if (data?.anexoPCD) {
                funcionario.anexosPCD = data?.anexoPCD;
            }
        },
        setInfoDemissao(data) {
            const funcionario = this.form.funcionarios.find((element) => element.id === data.funcionarioId);
            funcionario.dataHomologacao = data.dataHomologacao;
        },
        setInfoConsulta(data) {
            const funcionario = this.form.funcionarios.find((element) => element.id === data.funcionarioId);
            funcionario.detalheConsulta = data.detalheConsulta;
        },
        setInfoRetorno(data) {
            const funcionario = this.form.funcionarios.find((element) => element.id === data.funcionarioId);
            funcionario.tipoRetorno = data.tipoRetorno;
            funcionario.dataLiberacao = data.dataLiberacao;
            if (data?.anexos) {
                funcionario.anexoLaudo = data.anexos
            }
        },
        setInfoRisco(data) {
            const funcionario = this.form.funcionarios.find((element) => element.id === data.funcionarioId);
            funcionario.questionarioRisco = data.questionarioRisco;
            funcionario.questionarioHierarquiaId = funcionario.customerHierarchyId;
        },
        prevPage() {
            this.$emit('prev-page', { formData: this.form, pageIndex: 4 });
        },
        async criarPreSolicitacao() {
            this.submitted = true;

            const result = await this.validateForm(this);
            if (!result) {
                return;
            }

            this.$emit('save-pre-solicitacao');
        },
        async criarAgendamento() {
            this.submitted = true;

            const result = await this.validateForm(this);
            if (!result) {
                return;
            }

            this.$emit('save-agendamento');
        },
        async validateForm() {
            return await Promise.all(
                this.formData.funcionarios.map((funcionario) => {
                    if (this.$refs['FormSolicitacoesPcd' + funcionario.id]) {
                        if (!funcionario?.anexosPCD || !funcionario?.anexosPCD?.length) {
                            this.$toast.add({ severity: 'info', summary: 'O Anexo do Enquadramento PCD é obrigatório!', life: 3000 });
                            return false;
                        }
                    }
                    if (this.$refs['FormSolicitacoesDemissao' + funcionario.id]) {
                        return this.$refs['FormSolicitacoesDemissao' + funcionario.id].v$.dataHomologacao.$validate();
                    }
                    if (this.$refs['FormSolicitacoesConsulta' + funcionario.id]) {
                        return this.$refs['FormSolicitacoesConsulta' + funcionario.id].v$.detalheConsulta.$validate();
                    }
                    if (this.$refs['FormSolicitacoesRetorno' + funcionario.id]) {
                        if (!funcionario?.anexoLaudo || !funcionario?.anexoLaudo?.length) {
                            this.$toast.add({ severity: 'info', summary: 'O Anexo de Liberação INSS/Especialista é obrigatório!', life: 3000 });
                            return false;
                        }
                        return this.$refs['FormSolicitacoesRetorno' + funcionario.id].v$.form.$validate();
                    }
                    if (this.$refs['FormSolicitacoesRisco' + funcionario.id]) {
                        return this.$refs['FormSolicitacoesRisco' + funcionario.id].v$.questionarioRisco.$validate();
                    }
                    return true;
                })
            ).then((validates) => validates.every((validate) => validate === true));
        },
        setVisibleLines() {
            if (
                this.form.tipoExame.id === TipoExame.DEMISSIONAL ||
                this.form.tipoExame.id === TipoExame.CONSULTA ||
                this.form.tipoExame.id === TipoExame.RETORNO_AO_TRABALHO
            ) {
                this.expandedRows = this.form.funcionarios;
            } else {
                this.expandedRows = this.form.funcionarios.filter((funcionario) => !funcionario.possuiRisco || funcionario.pcd);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.p-card.no-border {
    box-shadow: unset;
}

.p-card.no-padding > :deep(.p-card-body) {
    padding: 0;
}
</style>

<template>
    <div class="p-fluid grid">
        <div class="field col-4 m-0">
            <label :class="{ 'p-error': v$.questionarioRisco.$invalid && this.submitted }" for="questionarioRisco">
                Questionário de riscos <span class="p-error">*</span>
            </label>
            <MultiselectQuestionarioRisco
                v-model="questionarioRisco"
                :class="{ 'p-invalid': v$.questionarioRisco.$invalid && this.submitted }"
                :modelValue="questionarioRisco"
                @update:modelValue="setFuncionarioRisco"
            />
            <small v-if="v$.questionarioRisco.$invalid && this.submitted" class="p-error">
                {{ v$.questionarioRisco.required.$message }}
            </small>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import MultiselectQuestionarioRisco from '../../../hierarquias/components/MultiSelectQuestionarioRisco.vue';

export default {
    components: { MultiselectQuestionarioRisco },
    setup: () => ({ v$: useVuelidate() }),
    props: {
        funcionarioId: {
            type: Number,
            required: true
        },
        submitted: {
            type: Boolean,
            required: true
        }
    },
    emits: ['update'],
    data() {
        return {
            questionarioRisco: null
        };
    },
    validations() {
        return {
            questionarioRisco: { required: helpers.withMessage('O questionário deve ser informado', required) }
        };
    },
    methods: {
        setFuncionarioRisco() {
            this.$emit('update', { funcionarioId: this.funcionarioId, questionarioRisco: this.questionarioRisco });
        }
    }
};
</script>

<template>
    <div class="p-fluid grid">
        <div class="field col-3 m-0">
            <label :class="{'p-error':v$.form.tipoRetorno.$invalid && this.submitted}" for="tipoRetorno">
                Tipo de retorno <span class="p-error">*</span>
            </label>
            <Dropdown
                id="tipoRetorno"
                v-model="form.tipoRetorno"
                :class="{'p-invalid':v$.form.tipoRetorno.$invalid && this.submitted}"
                :options="tiposRetorno"
                optionLabel="label"
                optionValue="value"
                placeholder="Selecione o tipo de retorno"/>
            <small v-if="(v$.form.tipoRetorno.$invalid && this.submitted)" class="p-error">
                {{ v$.form.tipoRetorno.required.$message }}
            </small>
        </div>
        <div class="field col-3 m-0">
            <label :class="{'p-error':v$.form.dataLiberacao.$invalid && this.submitted}" for="dataLiberacao">
                Data do retorno <span class="p-error">*</span>
            </label>
            <Calendar
                id="dataLiberacao"
                v-model="form.dataLiberacao"
                :class="{'p-invalid':v$.form.dataLiberacao.$invalid && this.submitted}"
                :showIcon="true"
                dateFormat="dd/mm/yy"
                selectionMode="single"/>
            <small v-if="(v$.form.dataLiberacao.$invalid && this.submitted)" class="p-error">
                {{ v$.form.dataLiberacao.required.$message }}
            </small>
        </div>
        <div class="field col-3 m-0" style="width: 100%;">
            <label for="anexo">
                Adicionar Anexo de Liberação INSS/Especialista <span class="p-error">*</span>
            </label>
            <div style="width: 50%;">
                <FileUpload
                        mode="advanced"
                        @uploader="uploadAnexo"
                        auto
                        customUpload
                        :maxFileSize="10485760"
                        :showCancelButton="false"
                        :showUploadButton="false"
                        invalidFileSizeMessage="O arquivo selecionado excede o tamanho de 10 Mb."
                        label="Importar"
                        chooseLabel="Importar"
                        class="mr-2 inline-block"
                        :style="{ width: '100%' }"
                    >
                        <template #empty>
                            <p>Arraste e solte o arquivo aqui.</p>
                        </template>
                </FileUpload>
                <DataTable
                    v-if="anexos.length"
                    dataKey="id"
                    :value="anexos"
                    :row-hover="true"
                    class="p-datatable-sm"
                    responsiveLayout="stack"
                    breakpoint="640px"
                >
                    <template #empty> Nenhum registro encontrado. </template>
    
                    <Column field="nome" header="Nome">
                    <template #body="{ data }">
                        <a :href="data.url" class="white-space-nowrap overflow-hidden text-overflow-ellipsis" style="max-width: 60vw" target="_blank">
                        {{ data.nome }}
                        </a>
                    </template>
                    </Column>
                    <Column field="tipo" header="Tipo"></Column>
                    <Column :exportable="false" style="min-width: 2rem">
                    <template #body="{data}">
                        <Button icon="pi pi-trash" class="p-button-text p-button-secondary" @click="removerAnexo(data)" />
                    </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

export default {
    setup: () => ({ v$: useVuelidate() }),
    props: {
        funcionarioId: {
            type: Number,
            required: true
        },
        submitted: {
            type: Boolean,
            required: true
        }
    },
    emits: ['update'],
    data() {
        return {
            form: {},
            tiposRetorno: [
                { label: 'B31 - Auxilio - Doença Previdenciário', value: 1 },
                { label: 'B91 - Auxilio - Doença por acidente do trabalho', value: 2 },
                { label: 'B94 - Auxilio - Acidente por acidente do trabalho', value: 3 },
                { label: 'Licença Maternidade', value: 4 },
                { label: 'Prestação do Serviço Militar', value: 5 }
            ],
            showAnexoRetorno: false,
            anexos: []
        };
    },
    validations() {
        return {
            form: {
                tipoRetorno: { required: helpers.withMessage('O tipo de retorno deve ser informado', required) },
                dataLiberacao: { required: helpers.withMessage('A data de liberação deve ser informada', required) },
            }
        };
    },
    watch: {
        form: {
            handler() {
                this.$emit('update', { funcionarioId: this.funcionarioId, ...this.form, anexos: this.anexos });
            },
            deep: true
        },
    },
    methods: {
        anexoRetorno() {
            this.showAnexoRetorno = true;
        },
        uploadAnexo(event) {
            const arquivoExistente = this.anexos.filter((anexo) => anexo.nome === event.files[0].name);

            if (arquivoExistente.length) {
                this.$toast.add({ severity: 'info', summary: 'Você já carregou este arquivo!', life: 3000 });
                return
            }
            this.anexos.push({
                nome: event.files[0].name,
                arquivo: event.files[0],
                tipo: 'LAUDO',
            });
            this.$emit('update', { funcionarioId: this.funcionarioId, ...this.form, anexos: this.anexos });
        },
        removerAnexo(index) {
           this.anexos = this.anexos.filter((anexo) => anexo.nome !== index.nome);
           this.$emit('update', { funcionarioId: this.funcionarioId, ...this.form, anexos: this.anexos });
        },
    }
};
</script>

<style scoped>

</style>
